<template>
  <div class="wscn-http404-container">
    <img src="@/assets/img/404.png" class="img-box">
    <p class="title">{{$t("notFoundPage.Wrongpage")}}</p>
    <p class="text">{{$t("notFoundPage.nonentityPage")}}</p>
      <el-button @click="gohome" plain>{{$t("notFoundPage.returnHome")}}（{{num}}）</el-button>
  </div>
</template>

<script>

export default {
  data() {
    return{
      num:5,
      times:null,
    }
  },
  mounted(){
    this.setNum();
  },
  methods:{
    setNum(){
      this.times = setInterval(()=>{
        this.num --;
        if(this.num == 0){
          this.$router.push({path:'/'});
          clearInterval(this.times)
        }
      },1000)
    },
    gohome(){
      this.$router.push({path:'/'});
      clearInterval(this.times);
    }
  }
};
</script>

<style lang="scss" scoped>
.wscn-http404-container{
  transform: translate(-50%,-50%);
  position: absolute;
  width: 80%;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  .img-box{
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .title{
    text-align: center;
    line-height: .8rem;
    margin-bottom: .4rem;
    font-size: .64rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .text{
    font-size: .52rem;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .el-button{
    width: max-content;
    margin: 0 auto;
    margin-top: .4rem;
    font-size: .64rem;
  }
}
</style>
